import { TeamNanoID } from '../../teamsTypes';

import { useTeam } from '../useTeam';

import {
  FETCH_TEAM_ACCOUNT_RECEIVABLES_QUERY,
  FetchTeamAccountReceivablesQueryResponse
} from '../../queries/fetchTeamAccountReceivables.query';

import { TeamCache } from '../../TeamCache';

interface TeamAccountReceivablesOptions {
  teamNanoId: TeamNanoID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

function useTeamAccountReceivables({
  teamNanoId,
  options
}: TeamAccountReceivablesOptions) {
  return useTeam<FetchTeamAccountReceivablesQueryResponse>({
    cacheKey: TeamCache.showCompanyAccountReceivablesCacheKey(),
    query: FETCH_TEAM_ACCOUNT_RECEIVABLES_QUERY,
    uuid: teamNanoId,
    options
  });
}

export default useTeamAccountReceivables;
