import map from 'lodash/map';
import first from 'lodash/first';

import { IconsEnum } from '../../../../assets/icons/types';
import { Currencies } from '../../../../types';

import {
  FETCH_CURRENCY_REATES_QUERY,
  FetchCurrencyRatesQueryResponse
} from '../../queries/fetchCurrencyRates.query';

import { useCurrencyRates } from '../../hooks/useCurrencyRates';

import { Translate } from '../../../../helpers/Translate';
import { NumberHelper } from '../../../../helpers/NumberHelper';
import { TooltipIconButtonHelper } from '../../../../helpers/buttons/TooltipIconButtonHelper';

import { CurrencyRateCache } from '../../CurrencyRateCache';

import { currencyRatesKeys } from '../../../../locales/keys';

interface ExchangeRatesListTooltipIconButtonProps {
  currencies: Currencies[];
}

function ExchangeRatesListTooltipIconButton({
  currencies
}: ExchangeRatesListTooltipIconButtonProps) {
  const { currencyRates } = useCurrencyRates<FetchCurrencyRatesQueryResponse>({
    initialLimit: 1,
    cacheKey: CurrencyRateCache.indexCacheKey(),
    query: FETCH_CURRENCY_REATES_QUERY
  });

  return (
    <TooltipIconButtonHelper
      icon={IconsEnum.INFORMATION_CIRCLE_SOLID}
      tooltipClassName="rounded-md bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-200 border border-gray-300 dark:border-gray-700"
      tooltipChildren={
        <>
          <div className="text-sm mb-1">
            <strong>
              <Translate id={currencyRatesKeys.exchangeRate} />
            </strong>
          </div>
          {map(currencies, (currency) => (
            <div className="text-sm" key={currency}>
              <NumberHelper value={1} /> {currency} ={' '}
              <NumberHelper
                value={1 / (first(currencyRates)?.data?.[currency] || 1)}
              />{' '}
              {Currencies.USD}
            </div>
          ))}
        </>
      }
    />
  );
}

export default ExchangeRatesListTooltipIconButton;
